@import '~spoton-lib/styles/responsive';
@import '~spoton-lib/styles/text';
@import '~spoton-lib/styles/color-variables';

$tablet: get-breakpoint('tablet');
$mobile: get-breakpoint('mobile');

.Container {
    max-width: 370px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    height: 100%;
    padding-top: 2.5rem;

    @include screen-smaller-than($mobile) {
        padding-top: 1rem;
    }
}

.Header {
    @include h1;
    font-size: 1.875rem;
    margin: 1.5rem 0 2rem;

    @include screen-smaller-than($tablet) {
        margin: 2.5rem 0 1.5rem;
    }

    @include screen-smaller-than($mobile) {
        margin: 0;
    }
}

.Subtitle {
    @include sub1;
    color: $base90;
    margin-bottom: 0.5rem;
}

.OfferSubtitle {
    @include p;
    color: $base70;
    margin-bottom: 1.4rem;

    @include screen-smaller-than($tablet) {
        margin: 0 auto 1.9rem;
    }

    @include screen-smaller-than($mobile) {
        margin: 0;
    }
}

.SignUpButton {
    margin: 3rem auto 1.625rem;
    max-width: 320px;
    align-content: center;
}

.Form {
    max-width: 19.875rem;
    width: 100%;
}

.EmailInput {
    padding: 0 0 0.5rem 0;

    input {
        // This is to fix zoom into an input on IOS
        font-size: 1rem;
    }

    @include screen-smaller-than($mobile) {
        width: 280px;
        margin: 0 auto;
    }

    &_label {
        @include label1;

        @include screen-smaller-than($mobile) {
            width: 280px;
            margin: 0 auto;
        }
    }
}

.ContinueGuestButton {
    margin: 0 auto;

    &_text {
        font-size: 0.875rem;
    }
}

.Error {
    font-weight: 600;
    color: $danger50;
    text-align: center;
}

.MerchantLogo {
    max-width: 86px;
    max-height: 86px;

    @include screen-smaller-than($mobile) {
        margin-bottom: 4.5rem;
    }
}

.DivSpinner {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    margin-top: 4rem;
}
