@import '~spoton-lib/styles/text';
@import '~spoton-lib/styles/responsive';

$tablet: get-breakpoint('tablet');

.Container {
    padding-top: 2rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    @include screen-smaller-than($tablet) {
        justify-content: space-around;
    }
}

.Header {
    @include h1;
    font-size: 1.875rem; // 30px
    margin: 1rem 0;

    @include screen-smaller-than($tablet) {
        margin: 0;
    }
}

.Subtitle {
    @include sub1;
    color: $base90;
}

.MerchantLogo {
    max-width: 86px;
    max-height: 86px;
}

.GoOnlineButton {
    margin: 3rem 0;

    @include screen-smaller-than($tablet) {
        margin: 0;
    }
}

.Legal {
    @include label1;
    color: $base70;
    text-align: center;
}

.Link {
    @include hyperlink;
    display: inline-block;
    color: $primary50;

    &:hover {
        cursor: pointer;
    }
}

.Error {
    font-weight: bold;
    color: red;
}

.Modal {
    width: unset;
    max-width: 600px;
    overflow-y: scroll;

    @include screen-smaller-than($tablet) {
        padding: 2rem 0;
    }
}
