@import "~spoton-lib/styles/text";
@import "~spoton-lib/styles/responsive";

$tablet: get-breakpoint("tablet");

.SignUpSuccess {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  height: 100%;
  padding-top: 2.5rem;

  &_title {
    @include h3;
    margin: 2rem 0;

    @include screen-smaller-than($tablet) {
      @include h5;
      margin: 3rem 0 2rem;
    }
  }

  &_merchantLogo {
    max-width: 86px;
    max-height: 86px;
  }

  &_subtitle {
    @include p;
    color: $base70;
    margin: 1.5rem 0;
  }

  &_goToBrowserBtn {
    margin-bottom: 1rem;
  }
}

.Container {
  padding-top: 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  @include screen-smaller-than($tablet) {
    justify-content: space-around;
  }
}

.Error {
  margin-top: 15px;
  font-weight: 600;
  color: $danger50;
  text-align: center;
}
