@import "~spoton-lib/styles/text";
@import "~spoton-lib/styles/responsive";

$tablet: get-breakpoint("tablet");

.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  height: 100%;
  padding-top: 2.5rem;
}

.Title {
  @include h3;
  margin: 2rem 0;

  @include screen-smaller-than($tablet) {
    @include h5;
    margin: 3rem 0 2rem;
  }
}

.SuccessImg {
  width: 340px;
  height: 200px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assets/images/connection-success-desktop.svg");

  @include screen-smaller-than($tablet) {
    background-image: url("../../assets/images/connection-success-mobile.svg");
  }
}

.MerchantLogo {
  max-width: 86px;
  max-height: 86px;
}

.GoToInternetButton {
  margin-top: 3rem;
}

.Container {
  padding-top: 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  @include screen-smaller-than($tablet) {
    justify-content: space-around;
  }
}

.Error {
  margin-top: 15px;
  font-weight: 600;
  color: $danger50;
  text-align: center;
}
