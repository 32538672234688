@import '~spoton-lib/styles/text';

.Prompt {
    h3 {
        @include h3;
        text-align: center;
    }

    p {
        @include p;
        text-align: center;
        padding: 1rem;
    }

    &_buttonGroup {
        display: flex;
        justify-content: center;
    }
}
