@import '~spoton-lib/styles/text';
@import '~spoton-lib/styles/responsive';

$tablet: get-breakpoint('tablet');

body {
    @include global-text-override;

}

.App {
    height: 100vh;
    background: url('assets/images/bg.svg');
    display: flex;
    flex-direction: column;
    &_content {
        flex: 1;
    }

    &_footer {
        display: flex;
        justify-content: center;
        padding: 1rem 0;

        @include screen-smaller-than($tablet) {
            padding: 0;
        }
    }
}

.SpotOnLogo {
    // Had to resize this way since the logo doesn't support classNames or styles
    > svg {
        width: 120px;
        padding: 1rem;
    }
}
